import { render } from "./OverView.vue?vue&type=template&id=20f96805&scoped=true"
import script from "./OverView.vue?vue&type=script&lang=ts"
export * from "./OverView.vue?vue&type=script&lang=ts"

import "./OverView.vue?vue&type=style&index=0&id=20f96805&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-20f96805"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QCard,QCardSection,QBtn,QTable,QTr,QTh,QTd});
